import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-basic-button',
  templateUrl: '../base-button/base-button.component.html',
  standalone: true,
  imports: [NgIf, MatLegacyButtonModule, NgTemplateOutlet, NgClass, MatIconModule],
})
export class BasicButtonComponent extends BaseButtonComponent {}
