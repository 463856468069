import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-raised-button',
  templateUrl: '../base-button/base-button.component.html',
  standalone: true,
  imports: [NgIf, MatLegacyButtonModule, NgTemplateOutlet, NgClass, MatIconModule],
})
export class RaisedButtonComponent extends BaseButtonComponent {
  @Input() override isRaisedButton = true;
}
