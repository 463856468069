import { Component, EventEmitter, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { BasicButtonComponent } from '../../buttons/basic-button/basic-button.component';
import { RaisedButtonComponent } from '../../buttons/raised-button/raised-button.component';

@Component({
  selector: 'app-mutation-notification',
  templateUrl: './mutation-notification.component.html',
  standalone: true,
  imports: [BasicButtonComponent, RaisedButtonComponent],
})
export class MutationNotificationComponent {
  @Output() saveEvent = new EventEmitter<void>();

  @Output() discardEvent = new EventEmitter<void>();

  constructor(private readonly snackbar: MatSnackBar) {}

  public saveChanges(): void {
    this.saveEvent.emit();
    this.snackbar.dismiss();
  }

  public discardChanges(): void {
    this.discardEvent.emit();
    this.snackbar.dismiss();
  }
}
